import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import picCalendar from '../assets/images/calendar-01-min.jpg'
import picBox from '../assets/images/box-04-min.png'
import picMissing from '../assets/images/missing-01-min.jpg'
import picContent from '../assets/images/analytics-02-min.jpg'
import picAnalytics from '../assets/images/analytics-04-min.jpg'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>How It Works | Parlour Party</title>
            <meta name="description" content="How It Works" />
        </Helmet>


        <section id="banner" className="style2">
            <div className="inner">
                <span className="image right" style={{"display": "block"}}>
                    <img src={picBox} alt="" style={{"max-width": "600px"}} />
                </span>
                <header className="major">
                    <h1>Parlour Party Box</h1>
                </header>
                {/* <div className="content">
                    
                    <p>
                        Our newest service is made 
                    </p>
                </div> */}
            </div>
        </section>

        <div id="main">
            <section id="one">
                
                <div className="inner">
                    <header className="major">
                        <h2>How It Works</h2>
                    </header>
                    <p>
                        We pride ourselves in offering a top notch experience to you and your guests.
                        Our customer first board game service will increase customer engagement, happiness and in turn, your profits.
                        Below is the foundation of our services and what you can expect from us as your board game partner. 
                    </p>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <div className="image">
                        <img src={picCalendar} alt="" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Monthly Game Rotation</h3>
                            </header>
                            <p>
                                Every month, we update each box with a fresh batch of games to ensure
                                game selection attracts players needs.
                            </p>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="image">
                        <img src={picMissing} alt="" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Maintenance</h3>
                            </header>
                            <p>
                                We take care of all maintenance of the games. This includes replacing damaged or missing pieces.
                            </p>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="image">
                        <img src={picContent} alt="" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Newsletters and Displays</h3>
                            </header>
                            <p>
                                We provide marketing materials and other content to promote to your guests.
                            </p>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="image">
                        <img src={picAnalytics} alt="" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Analytics</h3>
                            </header>
                            <p>
                                We utilize data analysis to identify user trends so that we provide the best experience to your customers.
                            </p>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Landing